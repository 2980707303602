import { inject, observer } from 'mobx-react';
import React from 'react';
import CartStore from '../../stores/Cart';
import ProductBlock from '../ProductBlock';
import Translate from '../Translate/Translate';
import './styles/index.scss';

interface Props {
  cart?: CartStore;
}

@inject('cart')
@observer
export default class Favourite extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.removeFavourite = this.removeFavourite.bind(this);
  }

  removeFavourite(product) {
    const { cart } = this.props;
    cart.toggleFavourite(product.slug);
  }

  render() {
    const { cart } = this.props;
    const favourites = cart.favourites.map((favourite) => {
      const product = cart.getProduct(favourite.id);

      return <ProductBlock key={favourite.id} product={product} />;
    });

    return (
      <div className="page-favourites">
        <h2>
          <Translate component="favourite" keyword="title" />
        </h2>
        <div className="page-favourites__grid">{favourites}</div>
      </div>
    );
  }
}
